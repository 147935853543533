import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { RegisterTicketComponent } from './components/register-ticket/register-ticket.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth-guard';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { ResolveGuard } from './guards/resolve.guard';
import { CommonModule } from '@angular/common';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent },
  { path: 'customer-details', component: CustomerDetailsComponent, canActivate: [AuthGuard],
    resolve: {
      data: ResolveGuard
  }},
  { path: 'register/:id', component: RegisterTicketComponent, canActivate: [AuthGuard] },
  { path: 'thank-you', component: ThankYouComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
  CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
