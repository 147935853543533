<div class="customer-details">
    <mat-grid-list cols="12">
        <div class="logo-top">
            <img src="../../../assets/images/logo_login.jpg"/>
        </div>
        <mat-card-content>
            <mat-card class="thank_you_card">
                <h2>{{'LAST_PAGE.MESSAGE' | translate}}</h2>
                <p>{{'LAST_PAGE.SUB_MESSAGE' | translate}}</p>
                <p>{{'LAST_PAGE.SUB_TEXT' | translate}}</p>
                <p>{{'LAST_PAGE.SUB_SUB_TEXT' | translate}}</p>
                <button (click)="goTo()" mat-raised-button color="primary" class="thank_you_button">{{'LAST_PAGE.BUTTON_VIEW' | translate}}</button>
            </mat-card>
        </mat-card-content>
    </mat-grid-list>
</div>
