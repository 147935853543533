<div class="ticket-verification">
  <mat-grid-list cols="12">
    <div class="logo-form">
      <img src="../../../assets/images/logo_login.jpg" class="logo" />
    </div>

    <div>
      <mat-card class="login-instructions">
        {{ "LOGIN.LOGIN_INSTRUCTION" | translate }}
      </mat-card>
    </div>

    <form [formGroup]="loginForm" (submit)="login()">
      <mat-card class="login-form"> 
        <mat-grid-list cols="12">
          <mat-form-field appearance="outline" class="input-form-size">
            <mat-label>{{ "LOGIN.TICKETID" | translate }}</mat-label>
            <input
              type="text"
              matInput
              minlength="3"
              formControlName="ticketId"
              placeholder="{{ 'LOGIN.TICKET_ID_REQUIRED' | translate }}"
              pattern="^[0-9][^.]*"
              (keypress)="isNumberKey($event)"
            />
            <mat-icon matSuffix>lock</mat-icon>
            <mat-error *ngIf="checkError('ticketId', 'required')"
              >{{ "LOGIN.TICKET_ID_REQUIRED" | translate }}
            </mat-error>
            <mat-error *ngIf="checkError('ticketId', 'pattern')">{{
              "LOGIN.TICKET_ID_INVALID" | translate
            }}</mat-error>
            <mat-error *ngIf="checkError('ticketId', 'minlength')"
              >Ticket should at least be 3 numbers long</mat-error
            >
          </mat-form-field>
        </mat-grid-list>

        <mat-grid-list cols="12">
          <mat-form-field class="input-form-size" appearance="outline">
            <mat-label>{{ "LOGIN.PASSWORD" | translate }}</mat-label>
            <input
              type="password"
              minlength="3"
              maxlength="6"
              matInput
              #password
              formControlName="securityCode"
              pattern="^[A-Za-z0-9]+$"
              placeholder="{{ 'LOGIN.PASSWORD_PLACEHOLDER' | translate }}"
            />
            <mat-icon matSuffix>security</mat-icon>
            <mat-error *ngIf="checkError('securityCode', 'required')">
              {{ "LOGIN.PASSWORD_REQUIRED" | translate }}</mat-error
            >
            <mat-error *ngIf="checkError('securityCode', 'pattern')">{{
              "LOGIN.PASSWORD_INVALID" | translate
            }}</mat-error>
            <mat-error *ngIf="checkError('securityCode', 'minlength')"
              >Security Code should at least be 3 characters long</mat-error
            >
          </mat-form-field>
        </mat-grid-list>
        <mat-grid-list cols="12">
          <button
            mat-raised-button
            color="primary"
            [disabled]="!loginForm.valid"
          >
            {{ "LOGIN.BUTTON" | translate }}
          </button>
        </mat-grid-list>
      </mat-card>
    </form>

    <div >
      <mat-card class="login-lang">
        <mat-form-field class="input-form-size" appearance="outline">
          <mat-select
            #langSelect
            (selectionChange)="switchLanguage(langSelect.value)"
            [value]="translate.currentLang"
          >
            <mat-select-trigger class="custom-align-icon">
              <span class="spacing">
                <mat-icon
                  svgIcon="{{ selectedLanguage.languageIcon }}"
                ></mat-icon>
              </span>
              {{ selectedLanguage.languageName }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let lang of languages"
              [value]="lang.languageCode"
              [selected]="lang.languageCode === translate.currentLang"
            >
              <span>
                <mat-icon svgIcon="{{ lang.languageIcon }}"></mat-icon>
              </span>
              {{ lang.languageName }}
            </mat-option>
          </mat-select>
          <mat-label>{{ "LOGIN.LANGUAGE.CHOOSE" | translate }}</mat-label>
        </mat-form-field>
      </mat-card>
    </div>
  </mat-grid-list>
</div>
