import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
  Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language, LanguageService } from 'src/app/services/language.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  public languageSwitched = false;
  public selectedLanguage: Language;
  @Input() heading: string;

  constructor(
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    private languageService: LanguageService
  ) {
    this.translate.use(this.translate.currentLang);
    this.selectedLanguage = this.languageService.getDefaultLang();
  }

  ngOnInit(): void {}

  public ngAfterViewChecked(): void {
    if (this.languageSwitched) {
      this.cd.detectChanges();
      this.languageSwitched = false;
    }
  }

  public switchLanguage(): void {
    this.languageSwitched = true;
    if (this.selectedLanguage.languageCode === 'en') {
      this.selectedLanguage = this.languageService.switchLanguage('no');
      this.translate.use(this.selectedLanguage.languageCode);
    } else {
      this.selectedLanguage = this.languageService.switchLanguage('en');
      this.translate.use(this.selectedLanguage.languageCode);
    }
    UtilService.createGAEvent(
      'event_change_language',
      'language_change',
      'event_change_language',
      this.selectedLanguage.languageCode
    );
  }
}
