<app-header [heading]="heading"></app-header>
<div class="ticket_registration" *ngIf="ticketId != null">
  <mat-card class="register_card">
    <form [formGroup]="registerTicketGroup" (submit)="registerTicket()">
      <mat-card-header>
        <mat-card-title class="title-align">{{
          "REGISTER_TICKET.TITLE" | translate
        }}</mat-card-title>
        <mat-card-subtitle class="verification-title">
          {{ "REGISTER_TICKET.SUBTITLE_BODY" | translate }}
        </mat-card-subtitle>
      </mat-card-header>
      <input type="hidden" formControlName="id" required />
      <div formGroupName="customer" class="input-align">
        <mat-card-content>
          <mat-grid-list cols="12">
            <mat-form-field appearance="outline" class="input-form-size">
              <mat-label>{{
                "REGISTER_TICKET.FORM.CUSTOMER.NAME" | translate
              }}</mat-label>
              <input
                formControlName="name"
                matInput
                #input
                pattern="^[a-z A-Z øæå ØÆÅ.'-]*"
                placeholder="{{
                  'REGISTER_TICKET.FORM.CUSTOMER.NAME_PLACEHOLDER' | translate
                }}"
              />
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.name.errors
                    ?.required
                "
                >{{
                  "REGISTER_TICKET.FORM.VALIDATION.NAME_REQUIRED" | translate
                }}</mat-error
              >
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.name.errors
                    ?.pattern
                "
                >{{
                  "REGISTER_TICKET.FORM.VALIDATION.NAME_INVALID" | translate
                }}</mat-error
              >
            </mat-form-field>
          </mat-grid-list>
          <mat-grid-list cols="12">
            <div class="cell-code">
              <div class="flag-select">
                <mat-select
                  #selectedCountry
                  (selectionChange)="switchCountry(selectedCountry.value)"
                  [value]="code"
                >
                  <mat-select-trigger class="custom-align-icon">
                    <span class="box">
                      <img class="flag" [src]="countryFlag" />
                      +{{ code }}
                    </span>
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let country of country"
                    [value]="country.dial_code"
                  >
                    <div class="box">
                      <img class="flag" [src]="getCountryFlag(country.code)" />
                      {{ country.name }} - ({{ country.dial_code }})
                    </div>
                  </mat-option>
                </mat-select>
              </div>

              <div class="input-cell-right">
                <mat-form-field appearance="outline" class="input-form-cell">
                  <mat-label>{{
                    "REGISTER_TICKET.FORM.CUSTOMER.CELL" | translate
                  }}</mat-label>
                  <input
                    type="number"
                    formControlName="cell"
                    matInput
                    #input
                    pattern="^[0-9 ]*$"
                    placeholder="{{
                      'REGISTER_TICKET.FORM.CUSTOMER.CELL_PLACEHOLDER'
                        | translate
                    }}"
                    (keypress)="isNumberKey($event)"
                  />
                  <mat-error
                    *ngIf="
                      registerTicketFormControl.customer.controls.cell.errors
                        ?.required
                    "
                    >{{
                      "REGISTER_TICKET.FORM.VALIDATION.CELL_REQUIRED"
                        | translate
                    }}</mat-error
                  >
                  <mat-error
                    *ngIf="
                      registerTicketFormControl.customer.controls.cell.errors
                        ?.pattern
                    "
                    >{{
                      "REGISTER_TICKET.FORM.VALIDATION.CELL_INVALID" | translate
                    }}</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
          </mat-grid-list>

          <mat-grid-list cols="12">
            <mat-form-field appearance="outline" class="input-form-size">
              <mat-label>{{
                "REGISTER_TICKET.FORM.ADDRESS.ADDRESS_LINE_1" | translate
              }}</mat-label>
              <input
                formControlName="address"
                matInput
                #input
                pattern="^[a-z A-Z øæå ØÆÅ.'&0-9]*"
                placeholder="{{
                  'REGISTER_TICKET.FORM.ADDRESS.ADDRESS_LINE_1_PLACEHOLDER'
                    | translate
                }}"
              />
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.address.errors
                    ?.pattern
                "
                >{{
                  "REGISTER_TICKET.FORM.VALIDATION.ADDRESS_INVALID" | translate
                }}</mat-error
              >
            </mat-form-field>
          </mat-grid-list>
          <mat-grid-list cols="12">
            <mat-form-field appearance="outline" class="input-form-zip">
              <mat-label>{{
                "REGISTER_TICKET.FORM.ADDRESS.ZIP" | translate
              }}</mat-label>
              <input
                type="text"
                formControlName="zip"
                matInput
                #postalCode
                pattern="^[0-9]*$"
                minlength="3"
                maxlength="4"
                placeholder="{{
                  'REGISTER_TICKET.FORM.ADDRESS.ZIP_PLACEHOLDER' | translate
                }}"
                (keypress)="isNumberKey($event)"
              />
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.zip.errors
                    ?.required
                "
                >{{
                  "REGISTER_TICKET.FORM.VALIDATION.ZIP_REQUIRED" | translate
                }}</mat-error
              >
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.zip.errors
                    ?.pattern
                "
                >{{
                  "REGISTER_TICKET.FORM.VALIDATION.ZIP_INVALID" | translate
                }}</mat-error
              >
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.zip.errors
                    ?.minlength
                ">{{"REGISTER_TICKET.FORM.VALIDATION.MINLENGTH" | translate}}</mat-error>
              <mat-error *ngIf="registerTicketFormControl.customer.controls.zip.errors?.maxlength">
                {{"REGISTER_TICKET.FORM.VALIDATION.MAXLENGTH" | translate}}
              </mat-error>
            </mat-form-field>
          </mat-grid-list>
          <mat-grid-list cols="12">
            <mat-form-field appearance="outline" class="input-form-size">
              <mat-label>{{
                "REGISTER_TICKET.FORM.ADDRESS.CITY" | translate
              }}</mat-label>
              <input
                formControlName="city"
                matInput
                #input
                pattern="^[a-z A-Z øæå ØÆÅ']*"
                placeholder="{{
                  'REGISTER_TICKET.FORM.ADDRESS.CITY_PLACEHOLDER' | translate
                }}"
              />
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.city.errors
                    ?.required
                "
                >{{
                  "REGISTER_TICKET.FORM.VALIDATION.CITY_REQUIRED" | translate
                }}</mat-error
              >
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.city.errors
                    ?.pattern
                "
                >{{ "REGISTER_TICKET.FORM.VALIDATION.CITY_INVALID" }}</mat-error
              >
            </mat-form-field>
          </mat-grid-list>

          <mat-grid-list cols="12">
            <mat-list-item>
              <mat-form-field appearance="outline" class="input-form-size">
                <mat-label>{{
                  "REGISTER_TICKET.FORM.CUSTOMER.EMAIL" | translate
                }}</mat-label>
                <input
                  type="email"
                  formControlName="email"
                  matInput
                  #input
                  placeholder="{{
                    'REGISTER_TICKET.FORM.CUSTOMER.EMAIL_PLACEHOLDER'
                      | translate
                  }}"
                />
                <mat-error
                  *ngIf="
                    registerTicketFormControl.customer.controls.email.errors
                  "
                  >{{
                    "REGISTER_TICKET.FORM.VALIDATION.EMAIL_INVALID" | translate
                  }}</mat-error
                >
              </mat-form-field>
            </mat-list-item>
          </mat-grid-list>

          <mat-grid-list cols="12" *ngIf="ssnTrigger()">
            <mat-form-field
              appearance="outline"
              class="input-form-account-number"
            >
              <mat-label>{{
                "REGISTER_TICKET.FORM.CUSTOMER.SSN_NUMBER" | translate
              }}</mat-label>
              <input
                type="text"
                formControlName="ssNum"
                matInput
                #input
                pattern="[0-9]*"
                placeholder="{{
                  'REGISTER_TICKET.FORM.CUSTOMER.SSN_NUMBER' | translate
                }}"
              />
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.ssNum.errors
                "
                >{{
                  "REGISTER_TICKET.FORM.VALIDATION.SSN_NUMBER_INVALID"
                    | translate
                }}</mat-error
              >
            </mat-form-field>
          </mat-grid-list>

          <mat-grid-list cols="12">
            <mat-form-field
              appearance="outline"
              class="input-form-account-number"
            >
              <mat-label>{{
                "REGISTER_TICKET.FORM.CUSTOMER.ACCOUNT_NUMBER" | translate
              }}</mat-label>
              <input
                type="text"
                formControlName="accountNumber"
                matInput
                #input
                pattern="[0-9]*"
                placeholder="{{
                  'REGISTER_TICKET.FORM.CUSTOMER.ACCOUNT_NUMBER_PLACEHOLDER'
                    | translate
                }}"
                (keypress)="isNumberKey($event)"
              />
              <mat-error
                *ngIf="
                  registerTicketFormControl.customer.controls.accountNumber
                    .errors
                "
                >{{
                  "REGISTER_TICKET.FORM.VALIDATION.ACCOUNT_NUMBER_INVALID"
                    | translate
                }}</mat-error
              >
            </mat-form-field>
          </mat-grid-list>
        </mat-card-content>
      </div>

      <mat-card-header>
        <mat-card-title>{{
          "REGISTER_TICKET.FORM.TICKET_SCAN.TITLE" | translate
        }}</mat-card-title>
        <mat-card-subtitle>
          {{ "REGISTER_TICKET.FORM.TICKET_SCAN.INSTRUCTION" | translate }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-grid-list cols="12" class="input-align input-form-upload-file">
          <button
            type="button"
            color="primary"
            mat-raised-button
            (click)="fileInput.click()"
          >
            {{ "REGISTER_TICKET.FORM.TICKET_SCAN.BUTTON_UPLOAD" | translate }}
          </button>
          <input
            type="file"
            mat-raised-button
            name="scannedTicket"
            placeholder="{{
              'REGISTER_TICKET.FORM.TICKET_SCAN.SCANNED_TICKET_PLACEHOLDER'
                | translate
            }}"
            (change)="onFileChange($event)"
            accept="image/jpg,image/jpeg,image/png,image/gif,application/pdf"
            #fileInput
          />
          <mat-error *ngIf="registerTicketFormControl.scannedTicket.errors">{{
            "REGISTER_TICKET.FORM.VALIDATION.SCANNED_TICKET_REQUIRED"
              | translate
          }}</mat-error>
        </mat-grid-list>
        <mat-grid-list cols="12" class="input-align">
          <mat-card *ngIf="image" class="ticket-scan-card">
            <img mat-card-image [src]="image" />
          </mat-card>
        </mat-grid-list>
        <mat-grid-list cols="12" class="input-align">
          <button
            mat-raised-button
            color="primary"
            [disabled]="!registerTicketGroup.valid"
          >
            {{ "REGISTER_TICKET.FORM.SUBMIT" | translate }}
          </button>
        </mat-grid-list>
      </mat-card-content>
    </form>
  </mat-card>
</div>
