import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {
  ticketInfo: any;
  prize: any;
  amount: any;
  ticketId = sessionStorage.getItem('ticketId');
  heading: string;
  headingSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.displayTicketInfo();
    this.headingSubscription = this.translate
      .get('HEADER', { value: 'TITLE_STAGE_1' })
      .subscribe((res: { TITLE_STAGE_2: string; TITLE_STAGE_1: string }) => {
        this.heading = res.TITLE_STAGE_1;
        //=> 'hello world'
      });
  }

  ngOnDestroy() {
    if (this.headingSubscription) this.headingSubscription.unsubscribe();
  }

  public displayTicketInfo(): any {
    this.ticketInfo = this.activatedRoute.snapshot.data.data;
    this.prize = UtilService.getNorwegianCurrencyFormat(
      this.activatedRoute.snapshot.data.data.prize
    );
    this.amount = UtilService.getNorwegianCurrencyFormat(
      this.activatedRoute.snapshot.data.data.amount
    );
    UtilService.createGAEvent(
      'event_ticket_info_display',
      'ticket_info_display',
      'event_ticket_info_display',
      this.ticketInfo
    );
  }

  public nextPage(): void {
    this.router.navigate(['/register/' + Number(this.ticketId)]);
  }
}
