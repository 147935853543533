import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from '../components/login/login.component';
import { RegisterTicketComponent } from '../components/register-ticket/register-ticket.component';
import { AngularMaterialModule } from './angular-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from '../components/header/header.component';
import { CustomerDetailsComponent } from '../components/customer-details/customer-details.component';
import { ThankYouComponent } from '../components/thank-you/thank-you.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { AlertComponent } from '../components/alert/alert.component';
import { AppRoutingModule } from '../app-routing.module';

const components = [
    LoginComponent, RegisterTicketComponent,
    HeaderComponent, CustomerDetailsComponent,
    ThankYouComponent, HeaderComponent,
    AlertComponent, SpinnerComponent
];

@NgModule({
  declarations: [ ...components ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [ ...components ],
})
export class AppLocalModule {
  constructor() {}
}
