import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AlertComponent } from '../components/alert/alert.component';

declare var gtag;

export interface Alert {
    message: string;
    type: number;
}

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    static dialog: any;

    constructor(private dialog: MatDialog) { }

    private static convertToCents(value: number): number {
        return value / 100;
    }

    public static getNorwegianCurrencyFormat(value: number): string {
        const norwegianCurrencyFormat = new Intl.NumberFormat(environment.countryCode,
            { style: 'currency', currency: environment.currency });
        return `${norwegianCurrencyFormat.format(this.convertToCents(value))}`;
    }

    public static validateAccountNumber(accountNo: string): boolean {
        let isValid = false;
        if (accountNo.toString().length > 10) {
            let results;
            const lastDigit = accountNo.toString().substring(accountNo.toString().length - 1);
            let sum = 0;
            ({ sum, results } = UtilService.checkAccountDigits(sum, accountNo, results));
            if (Number(results) === Number(lastDigit) && Number(accountNo.toString().length) === 11) {
                isValid = true;
            }
        }
        return isValid;
    }


    private static checkAccountDigits(sum: number, accountNo: string, results: any): any {
        for (let index = 0; index < 10; index++) {
            sum += Number(accountNo.toString().charAt(index)) * (7 - (index + 2) % 6);
        }
        sum = (sum % 11);
        switch (sum) {
            case 0:
                results = 0;
                break;
            default:
                results = (11 - sum);
                break;
        }
        return { sum, results };
    }

    public static createGAEvent(name: string, category: string, label: string, data: any): any {
        gtag('event', name, {
            event_category: category,
            event_label: label,
            value: data
        });
    }

    public static alertMessage(message: string, type: number): Alert {
        return {
            message,
            type
        };
    }

    public static openDialog(alert: Alert): any {
        this.dialog.open(AlertComponent, {
            data: alert,
            width: '30%'
        });
    }

    public isNumberKey(event: KeyboardEvent): boolean {
        // tslint:disable-next-line: deprecation
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        if (event.key === 'e' || event.key === '+' || event.key === '.') {
            event.preventDefault();
        }
        return true;
    }
}
