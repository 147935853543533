<app-header [heading]="heading"></app-header>
<div class="customer-details">
  <mat-card class="register_card" flex-gt-s>
    <mat-card-title class="verification-title">{{
      "TICKET_DETAILS.TITLE" | translate
    }}</mat-card-title>
    <mat-card-subtitle class="verification-title">
      <b>{{ "TICKET_DETAILS.SUBTITLE_HEAD" | translate }}</b> <br />
      {{ "TICKET_DETAILS.SUBTITLE_BODY" | translate }}
    </mat-card-subtitle>
    <mat-divider></mat-divider>
    <mat-list>
      <div mat-subheader>{{ "TICKET_DETAILS.SHOP_NAME" | translate }}</div>
      <mat-list-item>
        <div mat-line>{{ ticketInfo?.shop }}</div>
      </mat-list-item>
      <mat-divider matInset></mat-divider>
      <div mat-subheader>{{ "TICKET_DETAILS.PRIZE" | translate }}</div>
      <mat-list-item>
        <h4 matLine>{{ prize }}</h4>
      </mat-list-item>
      <mat-divider matInset></mat-divider>
      <div mat-subheader>{{ "TICKET_DETAILS.AMOUNT" | translate }}</div>
      <mat-list-item>
        <h4 matLine>{{ amount }}</h4>
      </mat-list-item>
      <mat-divider matInset></mat-divider>
      <div mat-subheader>{{ "TICKET_DETAILS.SDATE" | translate }}</div>
      <mat-list-item>
        <h4 matLine>{{ ticketInfo?.sdate | date: "dd.LL.yyyy HH:mm:ss" }}</h4>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-card-actions class="next-button">
        <button mat-raised-button (click)="nextPage()" color="primary">
          {{ "TICKET_DETAILS.BUTTON" | translate }}
        </button>
      </mat-card-actions>
    </mat-list>
  </mat-card>
</div>
