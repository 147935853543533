import { AbstractControl } from '@angular/forms';
import { UtilService } from '../services/util.service';

export function accountNumberValidator(control: AbstractControl): any {
  if (control && (control.value !== null) || control.value !== undefined) {
    if (!UtilService.validateAccountNumber(control.value)) {
      return {
        isError: true
      };
    }
  }
  return null;
}
