import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

export interface RegisterTicket {
  ticketId: number;
  ticketWorkflowStatusId: number;
  pwd: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private $status: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(private http: HttpClient) {}

  public login(user: any): Observable<RegisterTicket> {
    return this.http.post<any>(
      environment.baseUrl + '/ticket-verification/authorize',
      user
    );
  }

  public setStatus(data: boolean): void {
    this.$status.next(data);
  }

  public getStatus(): Observable<boolean> {
    return this.$status.asObservable();
  }

  public logout(): void {
    sessionStorage.removeItem('ticket');
    sessionStorage.removeItem('ticketId');
    sessionStorage.removeItem('pwd');
    this.$status.next(false);
  }

  ngOnDestroy(): void {
    console.log('login destroyed');
    this.$status.next(false);
  }
}
