import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { skipWhile, take, tap } from 'rxjs/operators';

export interface Ticket {
  id: number;
  name: string;
  cell: string;
  address: string;
  zip: string;
  city: string;
  email: string;
  accountNumber: number;
  ssNum: string;
}
export interface TicketScan {
  scannedTicket: File;
}
@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(private http: HttpClient) {}

  public registerTicket(data: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders().set('Accept', 'application/json'),
    };
    return this.http.post<Ticket>(
      environment.baseUrl + '/ticket-verification/create-verification',
      data,
      headers
    );
  }

  public getTicket(ticketId: number, securityCode: string): Observable<any> {
    return this.http
      .get<any>(
        environment.baseUrl +
          '/ticket-verification/ticket/' +
          ticketId +
          '/' +
          securityCode
      )
      .pipe(
        skipWhile((x) => !x),
        take(1),
        tap((ticket) =>
          sessionStorage.setItem('ticket', JSON.stringify(ticket))
        )
      );
  }
}
